.ribbonDiv {
  background-color: var(--color-grey-light);
  color: black;
  text-align: center;
  max-height: 0px;
  transition: all 0.2s ease-in-out 0s;
  padding: 0 5px;
  display: flex;
  position: relative;
}

body[data-domain='camperlab'] .ribbonDiv {
  display: block;
  color: white;
  padding: 4px 10px;
}

body[data-domain='nnormal'] .ribbonDiv {
  background-color: var(--color-black);
  font-family: var(--font-primary);
  font-weight: 300;
}

body[data-domain='nnormal'] .ribbonDiv :global .ant-carousel {
  color: var(--color-white);
  font-family: var(--font-primary);
  font-weight: 300;
}

.ribbonDivShow {
  padding: 5px;
  max-height: 100px;
}

.ribbonDiv :global .ant-carousel {
  line-height: 1;
}
.ribbonDiv :global .slick-slider {
  margin-bottom: 0px;
  margin-top: 0px;
}
.ribbonContent {
  display: flex;
  align-items: center;
  justify-content: center;
}

body[data-domain='camperlab'] .ribbonContent {
  @apply text-left;
}

.ribbonContent > div {
  display: flex;
  justify-content: center;
  max-width: calc(100% - 25px);
}

body[data-domain='camperlab'] .ribbonContent > div {
  @apply max-w-full justify-start;
}

.chevron:hover {
  cursor: pointer;
}
.ribbonContent > div > .chevron:first-child {
  transform: rotate(90deg);
  zoom: 0.6;
  position: relative;
  padding-top: 5px;
}
.ribbonContent > div > .chevron:not(:first-child) {
  transform: rotate(-90deg);
  zoom: 0.6;
  position: relative;
  padding-top: 5px;
}
.ribbonItem {
  font-size: 0.75em;
}
.ribbonItemLink {
  font-size: 0.75em;
  padding-bottom: 1px;
  cursor: pointer;
}
.ribbonDiv :global a {
  color: unset;
}
.chevronExpand {
  margin-right: 10px;
  zoom: 0.7;
  transition: transform 0.3s;
  height: 100%;
}

.chevronExpand > svg {
  margin: auto 0;
}
.chevronExpanded {
  transform: rotate(180deg);
}
.chevronExpand:hover {
  cursor: pointer;
}
.expandedContent {
  z-index: 100;
  position: absolute;
  padding: 40px;
  font-size: 0.75em;
  background-color: var(--color-grey-light);
  min-width: 100%;
  text-align: center;
}

body[data-domain='nnormal'] .expandedContent,
body[data-domain='camperlab'] .expandedContent,
.camperlabExpanded {
  background-color: var(--color-black);
  color: var(--color-white);
}

body[data-domain='nnormal'] .expandedContent > *,
body[data-domain='camperlab'] .expandedContent > *,
.camperlabExpanded > * {
  color: var(--color-white);
}

body[data-domain='camperlab'] .expandedContent {
  @apply px-2.5 py-1 pt-10 text-left text-xs uppercase lg:text-xxs;
}

.ribbonCamperlab .ribbonItem,
.ribbonCamperlab .ribbonItemLink {
  font-size: 1em;
}

.ribbonCarousel {
  width: 100%;
  margin: 0.25em 1em;
  display: block;
  align-self: center;
}

.ribbonWidgets {
  position: absolute;
  right: 0px;
  width: auto;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.ribbonWidgets > a,
.ribbonWidgets > span {
  font-size: var(--description-size);
}

.ribbonWidgets > span,
.ribbonWidgets > div,
.ribbonWidgets > a {
  margin: 0.25em 1em;
}

.ribbonCamperlab {
  background-color: var(--color-black);
}

.ribbonCamperlab .ribbonWidgets > span,
body[data-domain='nnormal'] .ribbonWidgets > span {
  color: var(--color-white);
}

.ribbonCamperlab .ribbonWidgets :global .account-icon > span,
body[data-domain='nnormal'] .ribbonWidgets :global .account-icon > span {
  color: var(--color-white);
}

body[data-domain='nnormal'] .ribbonWidgets :global .account-icon > span {
  text-transform: uppercase;
}

.ribbonCamperlab .ribbonContent .ribbonItemLink,
.ribbonCamperlab .ribbonContent .ribbonItem,
.ribbonCamperlab .chevron,
.ribbonCamperlab .chevronExpand {
  color: var(--color-white) !important;
}

.ribbonCamperlab :global .slick-slide {
  @apply py-0 text-xs uppercase lg:text-xxs;
}

body[data-domain='camperlab'] .ribbonItemLink,
body[data-domain='camperlab'] .ribbonItem {
  @apply py-0 text-xs uppercase lg:text-xxs;
}

.ribbonCamperlab :global a,
body[data-domain='nnormal'] .ribbonDiv :global a {
  color: var(--color-white) !important;
}

body[data-domain='camperlab'] .ribbonContent {
  justify-content: flex-start;
}

body[data-domain='camperlab'] .ribbonCarousel {
  margin: 0px;
  width: 50%;
}

@media screen and (min-width: 1024px) {
  body[data-domain='nnormal'] .ribbonItemLink {
    font-size: 0.875rem;
  }

  body[data-domain='nnormal'] .ribbonDiv :global .ant-carousel {
    font-size: 0.875rem;
  }
}

@media screen and (max-width: 768px) {
  .ribbonDiv {
    flex-direction: column;
  }

  .ribbonCarousel {
    width: 100%;
  }

  .ribbonWidgets {
    display: none;
  }

  .ribbonContent {
    justify-content: center;
  }

  body[data-domain='camperlab'] .ribbonContent {
    justify-content: flex-start;
  }
}

@media screen and (min-width: 768px) {
  body:not([data-domain='camperlab']) .ribbonDiv {
    /* alinear con logo tnm */
    padding-left: 24px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .ribbonContent {
    justify-content: flex-start;
  }

  .ribbonCarousel {
    margin: 0px;
    width: 60%;
  }

  .ribbonWidgets {
    position: static;
    right: unset;
    width: 40%;
  }
}

@media screen and (min-width: 768px) and (max-width: 1200px) {
  body[data-domain='nnormal'] .ribbonContent {
    justify-content: flex-start;
  }

  body[data-domain='nnormal'] .ribbonCarousel {
    margin-left: 0px;
  }
}
